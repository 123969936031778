import { LOG_TABS } from '@/const/tabs.enum'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

export default {
  data() {
    return {
      LOG_TABS,
      logTab: null,
    }
  },

  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(val) {
        if (isObjectEmpty(val)) window.location.query = { ...val, logTab: LOG_TABS[0].name }
      },
    },
  },
}
