<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar tile elevation="1" color="indigo lighten-5">
        <BackButton />
        <v-tabs v-model="logTab">
          <v-tab
            v-for="tab in LOG_TABS"
            :key="tab.name"
            @click="$router.replace({ query: { logTab: tab.name } })"
            :tab-value="tab.name"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <Logs />
    </v-card>
  </v-container>
</template>

<script>
import SetLogTabs from '@/mixins/setLogTabs'
import BackButton from '@/components/common/BackButton.vue'

export default {
  name: 'DeviceEventsList',

  components: {
    BackButton,
    Logs: () => import('@/components/common/Logs'),
  },

  mixins: [SetLogTabs],

  created() {
    this.logTab = this.$route.query?.logTab || this.LOG_TABS[0].name
  },
}
</script>

<style scoped></style>
